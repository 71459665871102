import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1524589205/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Offers`}</h1>
    <p>{`Fidel Offers help you create and manage card-linked offers with various retailers – all in one place. The Fidel Offers API serves as a Transaction qualification engine for Fidel Offers. Developers can create Offers via the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/v1/reference/create-offer"
      }}>{`Fidel Offers API`}</a>{`, which allows your application to create and update Offers, link and unlink Locations to the created Offers, activate and deactivate Offers on specific Cards and send the Offers for approval to a Brand. Brands and Merchants can interact with the Fidel Offers by using the `}<a parentName="p" {...{
        "href": "https://clo.fidel.uk"
      }}>{`Fidel CLO Dashboard`}</a>{`.`}</p>
    <h2>{`Offer Lifecycle`}</h2>
    <p>{`Offers can be accessed via the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/offers/pending"
      }}>{`Fidel Dashboard`}</a>{`. They are grouped into four categories: Requests, Upcoming, Live and Expired.`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/dashboard-offers.png",
        "alt": "Fidel Dashboard with Offers",
        "title": "Fidel Dashboard with Offers"
      }}></img></p>
    <h3>{`Requests`}</h3>
    <p>{`The Offer `}<inlineCode parentName="p">{`startDate`}</inlineCode>{` is set in the future, and/or there are no linked Locations.`}</p>
    <h3>{`Upcoming`}</h3>
    <p>{`The Offer has at least one Location linked, but the `}<inlineCode parentName="p">{`startDate`}</inlineCode>{` is in the future. Offers in this category will automatically become "live" when the `}<inlineCode parentName="p">{`startDate`}</inlineCode>{` is reached.`}</p>
    <h3>{`Live`}</h3>
    <p>{`The current date is between the Offer `}<inlineCode parentName="p">{`startDate`}</inlineCode>{` and `}<inlineCode parentName="p">{`endDate`}</inlineCode>{`. The Offers in this category are qualifying Transactions at the locations linked to them.`}</p>
    <h3>{`Expired`}</h3>
    <p>{`The current date is after the Offer `}<inlineCode parentName="p">{`endDate`}</inlineCode>{`. The Offers in this category have stopped qualifying Transactions.`}</p>
    <h2>{`Creating an Offer`}</h2>
    <p>{`There are multiple options for creating a new Offer. You can create an Offer via the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/offers/pending"
      }}>{`Fidel Dashboard`}</a>{`, in the Offers section. Alternatively, Developers can use the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/v1/reference/create-offer"
      }}>{`Create Offer endpoint`}</a>{` from the Offers API to create an Offer.`}</p>
    <h3>{`Create an Offer via Dashboard`}</h3>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/gifs/create-offers.gif",
        "alt": "Create Offer in Fidel Dashboard",
        "title": "Create Offer in Fidel API Dashboard"
      }}></img></p>
    <p>{`Once you have created an Offer, it will enter the Offer Lifecycle in the Requests category.`}</p>
    <h3>{`Create an Offer via Offers API`}</h3>
    <p>{`Here's a cURL example of using the endpoint, with only the minimum required parameters:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl -X POST https://api.fidel.uk/v1/offers \\
  -H 'Content-Type: application/json' \\
  -H 'Fidel-Key: <KEY>' \\
  -d '{
        "countryCode": "GBR",
        "name":"20% Off Netflix Subscription",
        "publisherId":"3693ac7e-3e2b-432c-8c60-2b786453ca9b",
        "brandId":"f8bdb5e7-85c3-4acb-8a59-1b7e9218e412",
        "startDate":"2020-04-25T00:00:00",
        "type":{
          "name":"discount",
          "value":20
        }
      }'
`}</code></pre>
    <h4>{`Required Parameters`}</h4>
    <p>{`These are the minimum required parameters to create a new Offer:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`brandId`}</inlineCode>{`: Unique identifier for the Brand presenting the Offer.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`countryCode`}</inlineCode>{`: Country where the Offer will be available.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`name`}</inlineCode>{`: Name of your Offer.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`publisherId`}</inlineCode>{`: Unique identifier for you, the same as your Fidel `}<inlineCode parentName="li">{`accountId`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`startDate`}</inlineCode>{`: The start date for the Offer. The `}<inlineCode parentName="li">{`startDate`}</inlineCode>{` time will be a local time relative to the Location where the Offer is active.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type: name`}</inlineCode>{`: Type of the Offer. Valid names are `}<inlineCode parentName="li">{`"amount"`}</inlineCode>{` and `}<inlineCode parentName="li">{`"discount"`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type: value`}</inlineCode>{`: Numeric value of the discount associated with the Offer.`}</li>
    </ul>
    <p>{`Offers with the type `}<inlineCode parentName="p">{`amount`}</inlineCode>{` will use the indicated country's currency and apply the value as the amount of the discount, for example, `}<inlineCode parentName="p">{`£20 Off`}</inlineCode>{`. The `}<inlineCode parentName="p">{`discount`}</inlineCode>{` type applies the value as a percentage discount, for example, `}<inlineCode parentName="p">{`20% Off`}</inlineCode>{`.`}</p>
    <h4>{`Optional Parameters`}</h4>
    <p>{`There are a range of optional parameters available, which influence how the Offer behaves on the Fidel platform. You can read more about the endpoint's full specification on our `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/create-offer"
      }}>{`API Reference`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`activation`}</inlineCode>{`: An object, showing if the Offer needs activation or not. Default is `}<inlineCode parentName="li">{`{ enabled: false }`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`activation: enabled`}</inlineCode>{`: Boolean showing if the Offer needs to be activated on Cards or not. If it's `}<inlineCode parentName="li">{`true`}</inlineCode>{`, the `}<inlineCode parentName="li">{`activation`}</inlineCode>{` object also has a `}<inlineCode parentName="li">{`qualifiedTransactionsLimit`}</inlineCode>{` property. `}<strong parentName="li">{`Please read the section below on Offer Activation before using this parameter.`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`activation: qualifiedTransactionsLimit`}</inlineCode>{`: Number of Transactions to qualify for each Offer activation. Default is 1.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`daysOfWeek`}</inlineCode>{`: Array of numbers, with possible values from 0 to 6, to indicate the days of the week. 0 = Sunday, 1 = Monday, etc.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`endDate`}</inlineCode>{`: The date to automatically end the Offer. Same as `}<inlineCode parentName="li">{`startDate`}</inlineCode>{`, the time will be a local time relative to the Location where the Offer was active.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`funded: id`}</inlineCode>{`: Unique identifier for the account that funds the Offer. For self-funded Offers, this is not required. In the test environment, all Offers are self-funded, so this will always be the same as your `}<inlineCode parentName="li">{`accountId`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`funded: type`}</inlineCode>{`: Type of Offer funding. Possible values are `}<inlineCode parentName="li">{`"merchant"`}</inlineCode>{`, `}<inlineCode parentName="li">{`"card-linking"`}</inlineCode>{` and `}<inlineCode parentName="li">{`"affiliate"`}</inlineCode>{`. In the test environment, you can only create card-linked Offers, so the funding type will always be `}<inlineCode parentName="li">{`"card-linking"`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`maxTransactionAmount`}</inlineCode>{`: Deprecated in favor of `}<inlineCode parentName="li">{`type: maxRewardAmount`}</inlineCode>{`. For example, a 10% reward with a max transaction amount of £100 can't generate a reward larger than £10, even if the transaction amount is higher than £100. The new value for maxRewardAmount will be 10 since we want to limit the reward to £10.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`minTransactionAmount`}</inlineCode>{`: Minimum transaction amount to qualify for the offer. For example, if your offer is to save 25% on purchases over £50, then the Offer should have a `}<inlineCode parentName="li">{`minTransactionAmount`}</inlineCode>{` of £50.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`metadata`}</inlineCode>{`: Object with your own metadata, will be returned on the Offer object.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`returnPeriod`}</inlineCode>{`: Number of days between when a Transaction was created and when a Transaction qualifies for the Offer. The qualified Transaction will have the `}<inlineCode parentName="li">{`offer.qualificationDate`}</inlineCode>{` set to the creation date plus the number of days in the return period.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`schemes`}</inlineCode>{`: Array of schemes for which a Transaction qualifies for the Offer. Possible values are `}<inlineCode parentName="li">{`"amex"`}</inlineCode>{`, `}<inlineCode parentName="li">{`"mastercard"`}</inlineCode>{` and `}<inlineCode parentName="li">{`"visa"`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type: maxRewardAmount`}</inlineCode>{`: Numeric value of the maximum amount to be awarded for the Offer. This only applies to `}<inlineCode parentName="li">{`discount`}</inlineCode>{` type offers. For example, a 10% reward with a max reward amount of 10 can't generate a reward larger than £10, even if the transaction amount is higher than £100.`}</li>
    </ul>
    <h3>{`Multiple Offers on the same Brand and Location`}</h3>
    <p>{`Each Transaction can be rewarded only once. If there is more than one Offer for the same Brand in the same Location for which a Transaction qualifies, the Fidel API platform has to select one of them which will provide the reward.`}</p>
    <p>{`The platform uses the following rules to select the Offer that will reward the cardholder:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In case both Offers generate different cashback values, the platform selects and qualifies the most valuable offer;`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In case both Offers generate the same cashback values, the platform selects and qualifies the most recent offer.`}</p>
      </li>
    </ol>
    <h4>{`Example:`}</h4>
    <p>{`Let's suppose the following offers are on the same brand and cardholder made a transaction of £100 at a brand’s location.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Offer 15 % Off:`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Offer £50 Off:`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`name: "discount"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`name: "amount"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`value: 15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`value: 50`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Applying the stacking rules, the above transaction will qualify for "Offer £50 Off" according to the first rule.`}</p>
    <h2>{`Linking Locations to Offers`}</h2>
    <p>{`Before an Offer goes live and starts qualifying transactions, you will need to link Locations to the Offer. Developers can use the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/add-location-to-offer"
      }}>{`Link Location to Offer`}</a>{` API endpoint to link any Location to an Offer. Similarly, the Fidel API has an endpoint to `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/unlink-location-to-offer"
      }}>{`unlink a Location from an Offer`}</a>{`.`}</p>
    <p>{`Here's a cURL example of using the endpoint, with two path parameters, for the `}<inlineCode parentName="p">{`offerId`}</inlineCode>{` and `}<inlineCode parentName="p">{`locationId`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl -X POST \\
  https://api.fidel.uk/v1/offers/feb9af3c-9b4e-49df-bb8f-13ae4ad8cd22/locations/1af3b7a0-4bfd-4b5e-a285-fab1c8a8421d \\
  -H 'Content-Type: application/json' \\
  -H 'Fidel-Key: <KEY>'
`}</code></pre>
    <h3>{`Linking All Brand Locations to an Offer`}</h3>
    <p>{`Developers can use the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/link-all-program-locations-to-offer"
      }}>{`Link All Brand Locations to Offer`}</a>{` API endpoint to link all Location from a Brand to an Offer.`}</p>
    <p>{`Here's a cURL example of using the endpoint, with the two required path parameters, for the `}<inlineCode parentName="p">{`offerId`}</inlineCode>{` and `}<inlineCode parentName="p">{`programId`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl -X POST \\
  https://api.fidel.uk/v1/offers/feb9af3c-9b4e-49df-bb8f-13ae4ad8cd22/programs/0228b979-6f7c-4238-a309-40f9d6efd3ea/locations \\
  -H 'Content-Type: application/json' \\
  -H 'Fidel-Key: <KEY>'
`}</code></pre>
    <h3>{`Linking Offers in the Dashboard`}</h3>
    <p>{`When you create an Offer in the Fidel Dashboard, the second step of the creation dialogue allows you to link Locations to the newly created Offer.`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/gifs/create-offer-location.gif",
        "alt": "Link Locations in Offer Creation",
        "title": "Link Locations in Offer Creation"
      }}></img></p>
    <p>{`If you need to link more Locations after you've created an Offer, the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/locations"
      }}>{`Locations list in the Dashboard`}</a>{` has a menu button next to each Location, which opens a contextual menu. Selecting 'Link to offer' in the context menu will open a drawer that lets you select a possible Offer to link.`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/dashboard-link-location.png",
        "alt": "Link to offer in Fidel Dashboard",
        "title": "Link to Offer in Fidel API Dashboard"
      }}></img></p>
    <p>{`Alternatively, you can edit an Offer in the Fidel Dashboard, which will allow you to link more Locations in the second step of the Offer drawer.`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/gifs/dashboard-edit-offer.gif",
        "alt": "Edit Offer Link Locations",
        "title": "Edit Offer Link Locations"
      }}></img></p>
    <h2>{`Offers with Activation`}</h2>
    <p>{`Offers with activation require an Offer to be activated on a Card before they can go through the qualification process. Developers can use the Offers API to specify an Offer requires activation. When `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/v1/reference/create-offer"
      }}>{`creating an Offer`}</a>{`, the `}<inlineCode parentName="p">{`activation`}</inlineCode>{` object should have the `}<inlineCode parentName="p">{`enabled: true`}</inlineCode>{` property and a `}<inlineCode parentName="p">{`qualifiedTransactionsLimit`}</inlineCode>{` property higher or equal to 1. The `}<inlineCode parentName="p">{`qualifiedTransactionsLimit`}</inlineCode>{` property specifies how many Transactions will be qualified for each Offer activation. Here's a cURL example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl -X POST https://api.fidel.uk/v1/offers \\
  -H 'Content-Type: application/json' \\
  -H 'Fidel-Key: <KEY>' \\
  -d '{
        "countryCode": "GBR",
        "name":"20% Off Netflix Subscription",
        "publisherId":"3693ac7e-3e2b-432c-8c60-2b786453ca9b",
        "brandId":"f8bdb5e7-85c3-4acb-8a59-1b7e9218e412",
        "startDate":"2020-04-25T00:00:00",
        "type":{
          "name":"discount",
          "value":20
        },
        "activation":{
          "enabled":true,
          "qualifiedTransactionsLimit":1
        }
      }'
`}</code></pre>
    <p>{`Offers need to be activated on Cards before the purchase to receive and qualify Transactions for Offers with activation. Developers can do that by using the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/activate-offer-on-card"
      }}>{`Activate Offer on Card`}</a>{` API endpoint as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl -X POST \\
  https://api.fidel.uk/v1/offers/:offerId/cards/:cardId \\
  -H 'Content-Type: application/json' \\
  -H 'Fidel-Key: <KEY>'
`}</code></pre>
    <p>{`After an Offer is activated on a Card, it will qualify the number of Transactions specified by the `}<inlineCode parentName="p">{`qualifiedTransactionsLimit`}</inlineCode>{` value. After the limit of Transactions is qualified, the Offer is automatically deactivated from the Card. If developers need to deactivate an Offer from the Card for any reason before that event, they can use the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/unlink-card-from-offer"
      }}>{`Deactivate Offer on Card`}</a>{` API endpoint.`}</p>
    <p>{`Note that when you link Locations to an Offer with activation, you will only receive Transactions from Cards where the Offer has been activated on. To receive all Transactions from all Cards, you will need to disable the Offer activation with `}<inlineCode parentName="p">{`activation: { enabled: false, qualifiedTransactionsLimit: 1 }`}</inlineCode>{` or unlink the Locations from the Offer.`}</p>
    <p>{`It is not possible to run an activation offer and a non-activation offer at the same time for the same brand. Should you wish to run two offers at the same time for the same brand, they must both be activation offers or both be non-activation offers. If you are running two offers at once, only one transaction webhook will be sent for whichever offer has the highest award.`}</p>
    <div className="info-box">
    <small>Test and Live environments</small><br />
    It is important to note that when testing Offers with activation in the test environmentm all test transactions created will be visible for testing purposes. In the live environment only Transactions for activated Offers on Cards will be received and qualified.
    </div>
    <h3>{`Offers with Activation in the Dashboard`}</h3>
    <p>{`You can create Offers with activation in the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/offers/pending"
      }}>{`Fidel Dashboard`}</a>{` as well. When creating an Offer, check the "Enable offer activation" checkbox. That will reveal a "1" transactions field, which you can use to change the number for the qualified transactions limit.`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/gifs/create-offers.gif",
        "alt": "Create Offer with Activation",
        "title": "Create Offer with Activation"
      }}></img></p>
    <p>{`To activate an Offer on a Card using the Fidel Dashboard, you'll want to go to the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/cards"
      }}>{`Cards list`}</a>{`. Each Card has a menu button next to them, which opens a contextual menu. Selecting 'Activate offer' in the context menu will open a drawer that lets you select a possible Offer to activate on the Card.`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/dashboard-activate-offer.png",
        "alt": "Activate offer on Card in Fidel Dashboard",
        "title": "Activate offer on Card in Fidel API Dashboard"
      }}></img></p>
    <h2>{`Transaction Qualification`}</h2>
    <p>{`When an Offer is Live, each transaction made by an enrolled Card will be analysed against the Offer parameters and will be qualified against the Offer. If the Offer has a return period, transactions will only qualify for the Offer after the return period has passed.`}</p>
    <p>{`In both cases, an `}<inlineCode parentName="p">{`offer`}</inlineCode>{` object is appended to the original Transaction object containing the qualification result data. In case the Transaction qualifies, `}<inlineCode parentName="p">{`cashback`}</inlineCode>{` and `}<inlineCode parentName="p">{`performanceFee`}</inlineCode>{` properties are calculated and the `}<inlineCode parentName="p">{`qualified`}</inlineCode>{` property is set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`. If the qualified Transaction was for an Offer with a return period, the `}<inlineCode parentName="p">{`offer.qualificationDate`}</inlineCode>{` is set to the Transaction creation date plus the number of days in the Offer return period. If the Transaction does not qualify, `}<inlineCode parentName="p">{`cashback`}</inlineCode>{` and `}<inlineCode parentName="p">{`performanceFee`}</inlineCode>{` properties will have a `}<inlineCode parentName="p">{`0`}</inlineCode>{` value. The `}<inlineCode parentName="p">{`qualified`}</inlineCode>{` property will be set to `}<inlineCode parentName="p">{`false`}</inlineCode>{` and a message explaining why the Offer did not qualify will be set in the `}<inlineCode parentName="p">{`message`}</inlineCode>{` property. If a Transaction does not qualify for multiple Offers, the rejection `}<inlineCode parentName="p">{`message`}</inlineCode>{` is for the most recent Offer. You can see examples for qualified and non-qualified Transactions below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`fileName:qualified-transaction.json
{
  "id": "7fdfd5d8-9589-402f-8477-4a727ad239a2",
  "accountId": "4ed4b62b-aa4c-43a1-8064-da6d1368e17a",
  "programId": "6e38aa0c-b7ef-46bd-b1bd-c07c648d9cba",
  "datetime": "2020-04-21T19:12:01",
  "created": "2020-04-21T19:12:01.744Z",
  "updated": "2020-04-21T19:12:01.744Z",
  "auth": true,
  "cardPresent": false,
  "cleared": false,
  "amount": 5,
  "currency": "USD",
  "wallet": null,
  "card": {
    "id": "bc538b71-31c5-4699-840a-6d4a08693314",
    "firstNumbers": "555500",
    "lastNumbers": "5001",
    "scheme": "visa",
    "metadata": {
      "customKey1": "customValue1",
      "customKey2": "customValue2"
    }
  },
  "brand": {
    "id": "9d136f2e-df99-4a08-a0a5-3bc1534b7db9",
    "name": "Starbucks",
    "logoUrl": null
  },
  "location": {
    "id": "7a916fbd-70a0-462f-8dbc-bd7dbfbea160",
    "address": "5th Avenue",
    "city": "New York",
    "postcode": "120000",
    "countryCode": "USA",
    "timezone": "America/New_York",
    "geolocation": {
      "latitude": 51.5152346,
      "longitude": -0.1310718
    },
    "metadata": {
      "customKey1": "customValue1",
      "customKey2": "customValue2"
    }
  },
  "offer": {
    "id": "7e55eeae-99d6-4daf-b8c4-ac9ca660e964",
    "cashback": 20,
    "message": [],
    "performanceFee": 3.2,
    "qualified": true,
    "qualificationDate": null
  },
  "identifiers": {
    "MID": "123456789",
    "mastercardTransactionSequenceNumber": "0000000000000",
    "mastercardRefNumber": "AABBCCDDE",
    "amexApprovalCode": "AA00BB",
    "visaAuthCode": "000000",
    "mastercardAuthCode": null
  }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`fileName:non-qualified-transaction.json
{
  "...": "...",
  "updated": "2020-04-19T12:12:00.000Z",
  "offer": {
    "id": "7e55efae-99d6-4daf-b8c4-ac9ca660e864",
    "cashback": 0,
    "performanceFee": 0,
    "qualified": false,
    "qualificationDate": null,
    "message": [
      "Transaction amount of 5 USD is lower than the offer's minimum transaction amount of 10 USD"
    ]
  }
}
`}</code></pre>
    <h2>{`Offer Object`}</h2>
    <p>{`The Offers API's central piece of data is the Fidel Offer object, which holds all the details about a card-linked Offer. The card-linked Offer has a set of parameters used to qualify any `}<a parentName="p" {...{
        "href": "/select/transactions"
      }}>{`Card Transaction`}</a>{` made at a participating Brand's linked Location.`}</p>
    <p>{`The Offer object looks similar to the following and will be returned by the API in a multitude of situations. Transaction objects will also have a smaller version of the object inside, making it easier to retrieve the full Offer object if necessary.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`fileName:offer.json
{
  "id": "feb9af3c-9b4e-49df-bb8f-13ae4ad8cd22",
  "accepted": true,
  "activation": {
    "enabled": true,
    "qualifiedTransactionsLimit": 1
  },
  "additionalTerms": null,
  "brandId": "f8bdb5e7-85c3-4acb-8a59-1b7e9218e412",
  "brandName": "API Reference",
  "brandLogoURL": "https://example.com/logo.png",
  "countryCode": "GBR",
  "created": "2020-07-29T13:45:36.191Z",
  "currency": "GBP",
  "daysOfWeek": [0, 1, 2, 3, 4, 5, 6],
  "endDate": null,
  "feeSplit": 70,
  "funded": {
    "id": "3693ac7e-3e2b-432c-8c60-2b786453ca9b",
    "type": "card-linking"
  },
  "live": true,
  "locationsTotal": 240,
  "maxTransactionAmount": 0,
  "minTransactionAmount": 0,
  "metadata": null,
  "name": "5% Off Netflix Every Month",
  "origin": {
    "id": "3693ac7e-3e2b-432c-8c60-2b786453ca9b",
    "type": "card-linking"
  },
  "priority": 1,
  "publisherId": "3693ac7e-3e2b-432c-8c60-2b786453ca9b",
  "returnPeriod": 15,
  "schemes": ["amex", "mastercard", "visa"],
  "startDate": "2020-06-30T00:00:00",
  "supplier": null,
  "type": {
    "name": "discount",
    "maxRewardAmount": 10,
    "value": 5
  },
  "status": "live",
  "updated": "2020-08-22T14:47:37.479Z"
}
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <dl>
  <div>
    <dt>
      <span><code>id</code></span>
      <em>string</em>
    </dt>
    <dd>Unique identifier for the object.</dd>
  </div>
  <div>
    <dt>
      <span><code>accepted</code></span>
      <em>boolean</em>
    </dt>
    <dd>Whether the Offer was accepted by the Brand. To send the Offer to a Brand for funding, see the <a href="https://reference.fidel.uk/reference/send-offer-to-brand">Send Offer to Brand</a> API endpoint.</dd>
  </div>
  <div>
    <dt>
      <span><code>activation</code></span>
      <em>object</em>
    </dt>
    <dd>Has an <code>enabled</code> <em>boolean</em> property, showing if the Offer needs activation or not. If the <code>enabled</code> flag is set to true, the <code>activation</code> object also has a <code>qualifiedTransactionsLimit</code> property, specifying the number of transactions to qualify for each Offer activation. You can read more about Offers with Activation below.</dd>
  </div>
  <div>
    <dt>
      <span><code>additionalTerms</code></span>
      <em>string</em>
    </dt>
    <dd>Support for additional Terms & Conditions related to the Offer. <code>null</code> by default.</dd>
  </div>
  <div>
    <dt>
      <span><code>brandId</code></span>
      <em>string</em>
    </dt>
    <dd>Unique identifier of the associated Brand.</dd>
  </div>
  <div>
    <dt>
      <span><code>brandName</code></span>
      <em>string</em>
    </dt>
    <dd>Name of the associated Brand.</dd>
  </div>
  <div>
    <dt>
      <span><code>brandLogoURL</code></span>
      <em>string</em>
    </dt>
    <dd>Logo URL of the associated Brand. <code>null</code> by default.</dd>
  </div>
  <div>
    <dt>
      <span><code>countryCode</code></span>
      <em>string</em>
    </dt>
    <dd><a href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3">ISO 3166-1 alpha-3</a> country code for the Country where the Offer is active.</dd>
  </div>
  <div>
    <dt>
      <span><code>created</code></span>
      <em>date</em>
    </dt>
    <dd><a href="https://en.wikipedia.org/wiki/ISO_8601">ISO 8601</a> date and time in UTC representing the creation date for the Offer object.</dd>
  </div>
  <div>
    <dt>
      <span><code>currency</code></span>
      <em>string</em>
    </dt>
    <dd><a href="https://en.wikipedia.org/wiki/ISO_4217">ISO 4217</a> currency code based on the <code>countryCode</code> value.</dd>
  </div>
  <div>
    <dt>
      <span><code>daysOfWeek</code></span>
      <em>array: number</em>
    </dt>
    <dd>Array of numbers between 0 and 6 representing the days of the week for which the Offer is active. Starting with Sunday.</dd>
  </div>
  <div>
    <dt>
      <span><code>endDate</code></span>
      <em>date</em>
    </dt>
    <dd>Date and time, in the <code>YYYY-MM-DDThh:mm:ss</code> format, for when the Offer expires. Note: Time is local to the Location. Defaluts to <code>null</code> for Offers that do not expire.</dd>
  </div>
  <div>
    <dt>
      <span><code>feeSplit</code></span>
      <em>number</em>
    </dt>
    <dd>Explains the amount to be awarded to the publisher (Fidel customer) as their performance fee. Offers with the <code>amount</code> type will use the indicated country’s currency and apply the value as the amount, for example, £2. Offers with the <code>discount</code> type applies the value as a percentage, for example, 2% of the transaction value.</dd>
  </div>
  <div>
    <dt>
      <span><code>funded</code></span>
      <em>object</em>
    </dt>
    <dd>Contains an <code>id</code> property, with the unique identifier for the account that is funding the Offer. Also contains a <em>string</em> <code>type</code> property, for the type of account that is funding the Offer. The type can have one of the values <code>"merchant" | "card-linking" | "affiliate"</code>.</dd>
  </div>
  <div>
    <dt>
      <span><code>live</code></span>
      <em>boolean</em>
    </dt>
    <dd>Whether the Offer should be created in the live or test Fidel environment.</dd>
  </div>
  <div>
    <dt>
      <span><code>locationsTotal</code></span>
      <em>number</em>
    </dt>
    <dd>Total number of Locations linked to the Offer.</dd>
  </div>
  <div>
    <dt>
      <span><code>maxTransactionAmount</code></span>
      <em>number</em>
    </dt>
    <dd>Maximum transaction amount generating a proportional reward.</dd>
  </div>
  <div>
    <dt>
      <span><code>minTransactionAmount</code></span>
      <em>number</em>
    </dt>
    <dd>Minimum amount needed for a Transaction to qualify for the Offer.</dd>
  </div>
  <div>
    <dt>
      <span><code>name</code></span>
      <em>string</em>
    </dt>
    <dd>Name of the Offer.</dd>
  </div>
  <div>
    <dt>
      <span><code>metadata</code></span>
      <em>object</em>
    </dt>
    <dd>Metadata to be associated with the Offer. Defaults to <code>null</code>.</dd>
  </div>
  <div>
    <dt>
      <span><code>origin</code></span>
      <em>object</em>
    </dt>
    <dd>Contains an <code>id</code> property, with the unique identifier for the account that created the Offer. Also contains a <em>string</em> <code>type</code> property, for the type of account that created the Offer. The type can have one of the values <code>"merchant" | "card-linking" | "affiliate"</code>.</dd>
  </div>
  <div>
    <dt>
      <span><code>priority</code></span>
      <em>number</em>
    </dt>
    <dd>Not in use. Its value is always 1.</dd>
  </div>
  <div>
    <dt>
      <span><code>publisherId</code></span>
      <em>string</em>
    </dt>
    <dd>Unique identifier of the Publisher. Refers to <code>accountId</code>.</dd>
  </div>
  <div>
    <dt>
      <span><code>returnPeriod</code></span>
      <em>number</em>
    </dt>
    <dd>Number of days before a Transaction qualifies for the Offer.</dd>
  </div>
  <div>
    <dt>
      <span><code>schemes</code></span>
      <em>array: string</em>
    </dt>
    <dd>Card Schemes for which the Offer is valid. Possible values in the array are <code>visa</code>, <code>mastercard</code> and <code>amex</code>.</dd>
  </div>
  <div>
    <dt>
      <span><code>startDate</code></span>
      <em>date</em>
    </dt>
    <dd>Date and time, in the <code>YYYY-MM-DDThh:mm:ss</code> format, for when the Offer is activated and starts qualifying transactions.</dd>
  </div>
  <div>
    <dt>
      <span><code>status</code></span>
      <em>string</em>
    </dt>
    <dd>Offer status corresponding to the state in the <a className="content" href="/offers/#offer-lifecycle">Offer Lifecycle</a>. Can be one of <code>requests</code>, <code>upcoming</code>, <code>live</code> or <code>expired</code>.</dd>
  </div>
  <div>
    <dt>
      <span><code>supplier</code></span>
      <em>object</em>
    </dt>
    <dd>Contains an <code>id</code> property, with the unique identifier for the account that supplies the Offer. Also contains a <em>string</em> <code>type</code> property, for the type of account that supplies the Offer. The type can have one of the values <code>"merchant" | "card-linking" | "affiliate"</code>. Defaluts to <code>null</code>.</dd>
  </div>
  <div>
    <dt>
      <span><code>type</code></span>
      <em>object</em>
    </dt>
    <dd>Represents the type of Offer: a fixed amount or a percentage of the original transaction The <code>name</code> property can have one of the following two values: <code>amount</code> and
      <code>discount</code>. The <code>value</code> property has either the fixed amount of currency to be rewarded or the percentage value, depending on the Offer type. The <code>maxRewardAmount</code> property has a maximum fixed amount of currency to be rewarded for percentage typed Offers. <code>maxRewardAmount</code> only applies to discount Offers, and will be <code>null</code> for amount Offers.</dd>
  </div>
  <div>
    <dt>
      <span><code>updated</code></span>
      <em>date</em>
    </dt>
    <dd><a href="https://en.wikipedia.org/wiki/ISO_8601">ISO 8601</a> date and time in UTC representing the last time the Offer object was updated.</dd>
  </div>
    </dl>
    <h2>{`Deleting Offers`}</h2>
    <p>{`You can delete offers in the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/offers/pending"
      }}>{`Fidel API Dashboard`}</a>{`, or using the API's `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/delete-offer"
      }}>{`Delete Offer`}</a>{` endpoint.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl -X DELETE \\
  https://api.fidel.uk/v1/offers/ec80c3a1-0899-4e10-abdf-2dfb699b509c \\
  -H 'Content-Type: application/json' \\
  -H 'Fidel-Key: sk_test_50ea90b6-2a3b-4a56-814d-1bc592ba4d63'
`}</code></pre>
    <h2>{`API Reference`}</h2>
    <p>{`If you're looking to find out more about our Offers API and how to use it with your application, please visit the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/create-offer"
      }}>{`Fidel API Reference`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      